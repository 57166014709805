import { Moment } from 'moment';
import {
  Account, AccountActions,
  AccountBalance,
  AccountFinance,
  AccountNotificationPreference,
  RESET_CURRENT_ACCOUNT_BALANCE,
  RESET_CURRENT_ACCOUNT_FINANCE,
  SET_ACCOUNT_INVITES_COUNT, SET_ACCOUNT_INVITES_LIST,
  SET_ACCOUNT_NOTIFICATION_PREFERENCES, SET_ACCOUNT_WAYBILL_STAT, SET_ACCOUNTS_COUNT,
  SET_ACCOUNTS_LIST, SET_ARREARS_COUNT, SET_ARREARS_LIST,
  SET_BREADCRUMB_ACCOUNT, SET_CURRENT_ACCOUNT,
  SET_CURRENT_ACCOUNT_BALANCE,
  SET_CURRENT_ACCOUNT_FINANCE,
  SET_MANUAL_BONUS_COUNT,
  SET_MANUAL_BONUS_LIST,
  ADD_MANAGER_TO_ACCOUNT,
  UserInvite,
  WaybillAccountStat,
  UPDATE_ACCOUNT_HIDNUM_STATUS,
  UPDATE_ACCOUNT_NDS_INCREMENT,
  CompanySpendsItem,
  SET_COMPANY_SPENDS,
  SET_CURRENT_ACCOUNT_BITRIX_ID,
} from './types';

export interface AccountState {
  accountsList: Account[],
  accountsCount: number,
  arrearsList: Account[],
  arrearsCount: number,
  currentAccount: Account | null,
  accountInvitesList: Array<UserInvite>,
  accountInvitesCount: number,
  breadcrumbAccount: Account | null,
  currentAccountBalance: AccountBalance | null,
  currentAccountFinance: AccountFinance | null,
  waybillStat: Array<WaybillAccountStat>,
  currentStatTotalCount: number,
  currentStatTotalAmount: number,
  manualBonusList: Array<any>,
  manualBonusCount: number,
  accountNotificationPreferences: AccountNotificationPreference[],
  companySpends: CompanySpendsItem[]
}

const initialState: AccountState = {
  accountsList: [],
  accountsCount: 0,
  arrearsList: [],
  arrearsCount: 0,
  currentAccount: null,
  accountInvitesList: [],
  accountInvitesCount: 0,
  breadcrumbAccount: null,
  currentAccountBalance: null,
  currentAccountFinance: null,
  waybillStat: [],
  currentStatTotalCount: 0,
  currentStatTotalAmount: 0,
  manualBonusList: [],
  manualBonusCount: 0,
  accountNotificationPreferences: [],
  companySpends: []
};

export default function (state = initialState, action: AccountActions): AccountState {
  switch (action.type) {
    case SET_ACCOUNTS_LIST:
      return { ...state, accountsList: action.accounts };
    case SET_ACCOUNTS_COUNT:
      return { ...state, accountsCount: action.count };
    case SET_ARREARS_LIST:
      return { ...state, arrearsList: action.arrears };
    case SET_ARREARS_COUNT:
      return { ...state, arrearsCount: action.count };
    case SET_CURRENT_ACCOUNT:
      return { ...state, currentAccount: action.account };
    case SET_ACCOUNT_INVITES_LIST:
      return { ...state, accountInvitesList: action.invites };
    case SET_ACCOUNT_WAYBILL_STAT:
      return {
        ...state, waybillStat: action.series, currentStatTotalCount: action.totalCount, currentStatTotalAmount: action.totalAmount,
      };
    case SET_ACCOUNT_INVITES_COUNT:
      return { ...state, accountInvitesCount: action.count };
    case SET_BREADCRUMB_ACCOUNT:
      return { ...state, breadcrumbAccount: action.account };
    case SET_CURRENT_ACCOUNT_BALANCE:
      return { ...state, currentAccountBalance: action.balance };
    case RESET_CURRENT_ACCOUNT_BALANCE:
      return { ...state, currentAccountBalance: initialState.currentAccountBalance };
    case SET_CURRENT_ACCOUNT_FINANCE:
      return { ...state, currentAccountFinance: action.finance };
    case RESET_CURRENT_ACCOUNT_FINANCE:
      return { ...state, currentAccountFinance: initialState.currentAccountFinance };

    case SET_MANUAL_BONUS_LIST:
      return { ...state, manualBonusList: action.manualBonusList };
    case SET_MANUAL_BONUS_COUNT:
      return { ...state, manualBonusCount: action.manualBonusCount };
    case SET_ACCOUNT_NOTIFICATION_PREFERENCES:
      return { ...state, accountNotificationPreferences: action.preferences };

    case UPDATE_ACCOUNT_HIDNUM_STATUS:
      if (state.currentAccount) return { ...state, currentAccount: { ...state.currentAccount, hidnumStatus: action.payload } };
      return { ...state }

    case UPDATE_ACCOUNT_NDS_INCREMENT:
      if (state.currentAccount) return { ...state, currentAccount: { ...state.currentAccount, isRateNdsIncrement: action.payload } };
      return { ...state }

    case ADD_MANAGER_TO_ACCOUNT:
      return { ...state, accountsList: state.accountsList.map(i =>  {
        if (i.accountId === action.payload.accountId) {
          return {...i, managerId: action.payload.managerId}
        }
        return i;
      })}

    case SET_COMPANY_SPENDS:
      return { ...state, companySpends: action.payload }

    case SET_CURRENT_ACCOUNT_BITRIX_ID:
      return { 
        ...state, 
        currentAccount: state.currentAccount ? { ...state.currentAccount, bitrixId: action.payload } : null,
      }
  
    default:
      return state;
  }
}

import { adaptFromApi, adaptToApi } from '../../utils/adapter';
import { ThunkAction } from '../../utils/types';
import {
  ADD_MANAGER_TO_ACCOUNT,
  Account,
  AccountBalance,
  AccountFinance,
  AccountLoyalty,
  AccountNotificationPreference,
  AccountRank,
  AccountStatusType, AddManagerToAccount, CompanySpendsItem, NotificationPreference,
  SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  SET_COMPANY_SPENDS,
  SET_CURRENT_ACCOUNT_BITRIX_ID,
  SetAccountNotificationPreferences,
  SetCompanySpends,
  SetCurrentAccountBitrixId,
  UPDATE_ACCOUNT_HIDNUM_STATUS,
  UPDATE_ACCOUNT_NDS_INCREMENT,
  UpdateAccountHidnumStatus,
  UpdateAccountNdsIncrement,
  UserInvite,
  WaybillAccountStat,
} from './types';

export const setAccountsList = (accounts: Array<Account>) => ({
  type: 'SET_ACCOUNTS_LIST',
  accounts,
});

export const setAccountsCount = (count: number) => ({
  type: 'SET_ACCOUNTS_COUNT',
  count,
});

export const setArrearsList = (arrears: Array<any>) => ({
  type: 'SET_ARREARS_LIST',
  arrears,
});

export const setArrearsCount = (count: number) => ({
  type: 'SET_ARREARS_COUNT',
  count,
});

// Detail
export const setCurrentAccount = (account: Account) => ({
  type: 'SET_CURRENT_ACCOUNT',
  account,
});

export const resetCurrentAccount = () => ({
  type: 'SET_CURRENT_ACCOUNT',
  account: null,
});

export const setAccountInvitesList = (invites: Array<UserInvite>) => ({
  type: 'SET_ACCOUNT_INVITES_LIST',
  invites,
});

export const setWaybillAccountStat = (series: Array<WaybillAccountStat>, totalCount: number, totalAmount: number) => ({
  type: 'SET_ACCOUNT_WAYBILL_STAT',
  series,
  totalCount,
  totalAmount,
});

export const setAccountInvitesCount = (count: number) => ({
  type: 'SET_ACCOUNT_INVITES_COUNT',
  count,
});

export const resetAccountInvitesCount = (): ThunkAction<void> => (dispatch) => {
  dispatch(setAccountInvitesList([]));
  dispatch(setAccountInvitesCount(0));
};

export const setBreadcrumbAccount = (account: Account | null) => ({
  type: 'SET_BREADCRUMB_ACCOUNT',
  account,
});

export const setCurrentAccountBalance = (balance: AccountBalance) => ({
  type: 'SET_CURRENT_ACCOUNT_BALANCE',
  balance,
});

export const setCurrentAccountFinance = (finance: AccountFinance) => ({
  type: 'SET_CURRENT_ACCOUNT_FINANCE',
  finance,
});

export const resetCurrentAccountBalance = () => ({
  type: 'RESET_CURRENT_ACCOUNT_BALANCE',
});

export const resetCurrentAccountFinance = () => ({
  type: 'RESET_CURRENT_ACCOUNT_FINANCE',
});

export const setAccountsContract = (contract: number | null) => ({
  type: 'SET_ACCOUNTS_CONTRACT',
  contract,
});

export const setManualBonusList = (manualBonusList: Array<any>) => ({
  type: 'SET_MANUAL_BONUS_LIST',
  manualBonusList,
});

export const setManualBonusCount = (manualBonusCount: number) => ({
  type: 'SET_MANUAL_BONUS_COUNT',
  manualBonusCount,
});

export const setAccountNotificationPreferences = (preferences: AccountNotificationPreference[]): SetAccountNotificationPreferences => ({
  type: SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  preferences,
});

export const resetAccountNotificationPreferences = (): SetAccountNotificationPreferences => ({
  type: SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  preferences: [],
});

export const addManagerToAccount = (accountId: number, managerId?: number): AddManagerToAccount => ({
  type: ADD_MANAGER_TO_ACCOUNT,
  payload: {accountId, managerId},
});

export const updateAccountHidnumStatus = (hidnumStatus: boolean): UpdateAccountHidnumStatus => ({
  type: UPDATE_ACCOUNT_HIDNUM_STATUS,
  payload: hidnumStatus,
});

export const updateAccountNdsIncrement = (hidnumStatus: boolean): UpdateAccountNdsIncrement => ({
  type: UPDATE_ACCOUNT_NDS_INCREMENT,
  payload: hidnumStatus,
});

export const setCompanySpends = (payload: CompanySpendsItem[]): SetCompanySpends => ({
  type: SET_COMPANY_SPENDS,
  payload,
});

export const setCurrentAccountBitrixId = (payload: number): SetCurrentAccountBitrixId => ({
  type: SET_CURRENT_ACCOUNT_BITRIX_ID,
  payload,
})

export const addAccountManager = (accountId: number, managerId: number): ThunkAction => (
  dispatch, getState, http,
) => {
  return http.patch(`/api/v1/accounts/add_manager/${accountId}/`, adaptToApi({managerId})).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      if (parsed) {
        dispatch(addManagerToAccount(parsed.accountId, parsed.managerId))
      }
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const removeAccountManager = (accountId: number): ThunkAction => (
  dispatch, getState, http,
) => {
  return http.patch(`/api/v1/accounts/remove_manager/${accountId}/`).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      if (parsed) {
        dispatch(addManagerToAccount(parsed.accountId))
      }
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const updateAccountHidnum = (accountId: number, nextHidnumStatus: boolean): ThunkAction => (
  dispatch, getState, http,
) => {
  return http.patch(`/api/v1/accounts/update/hidnum/${accountId}/`, adaptToApi({nextHidnumStatus})).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      if (parsed) {
        dispatch(updateAccountHidnumStatus(parsed.hidnumStatus))
      }
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const updateAccountNds = (accountId: number, nextRateNdsIncrement: boolean): ThunkAction => (
  dispatch, getState, http,
) => {
  return http.patch(`/api/v1/accounts/update/ndsincrement/${accountId}/`, adaptToApi({nextRateNdsIncrement})).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      if (parsed) {
        dispatch(updateAccountNdsIncrement(parsed.rateNdsIncrement))
      }
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadAccounts = (search = '', page = 1, status = null, contractDateFrom = null, contractDateTo = null, sellerId = null, originalContract = null, growthType = null, preferredManager = null): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { query: search, page };
  if (status) params.status = status;
  if (contractDateFrom) params.contract_date_from = contractDateFrom;
  if (contractDateTo) params.contract_date_to = contractDateTo;
  if (sellerId) params.seller_id = sellerId;
  if (originalContract) params.original_contract = originalContract;
  if (growthType) params.growth_type = growthType;
  if (preferredManager) params.preferred_manager = preferredManager;
  return http.get('/api/v1/accounts/list/', params).then(
    (response: any) => {
      const { accounts, count } = response;
      dispatch(setAccountsList(accounts || []));
      dispatch(setAccountsCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadArrears = (page = 1): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/accounts/arrears/list/', { limit: 25, skip: page * 25 - 25 },
).then(
  (response: any) => {
    const { items, count } = response;
    dispatch(setArrearsList(items || []));
    dispatch(setArrearsCount(count));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadAccount = (accountId: number | string, setStore = true): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/accounts/detail/${accountId}/`,
).then(
  (response: any) => {
    if (setStore) dispatch(setCurrentAccount(response));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadAccountCounters = (accountId: number | string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/accounts/counters/${accountId}/`,
);

export const loadAccountInvites = (accountId: number | string, query: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/accounts/invites/', { account_id: accountId, query },
).then(
  (response: {count: number, invites: Array<UserInvite>}) => {
    dispatch(setAccountInvitesList(response.invites));
    dispatch(setAccountInvitesCount(response.count));
    return Promise.resolve(response);
  },
);

export const loadWaybillAccountStat = (accountId: number | string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/statistic/waybill/account/${accountId}/`,
).then(
  (response: {series: Array<WaybillAccountStat>, totalCount: number, totalAmount: number}) => {
    dispatch(setWaybillAccountStat(response.series, response.totalCount, response.totalAmount));
    return Promise.resolve(response);
  },
);

export const inviteUser = (data: any): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/accounts/invites/create/', data,
);

export const updateAccountLoyalty = (accountId: string | number, loyalty: AccountLoyalty): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/accounts/loyalty/${accountId}/update/`, { loyalty },
);

export const loadCurrentAccountBalance = (accountId: string | number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/accounts/balance/${accountId}/`,
).then(
  (response: AccountBalance) => {
    dispatch(setCurrentAccountBalance(response));
    return Promise.resolve(response);
  },
);

export const loadCurrentAccountFinance = (accountId: string | number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/accounts/finance/${accountId}/`,
).then(
  (response: AccountFinance) => {
    dispatch(setCurrentAccountFinance(response));
    return Promise.resolve(response);
  },
);

export const updateAccountRank = (rank: AccountRank, accountId: string | number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/accounts/rank/${accountId}/update/`, { rank },
);

export const getManualBonusList = (accountId: string | number, page: number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/accounts/bonus/${accountId}/`, { limit: 10, skip: page * 10 - 10, account_id: accountId },
).then(
  (response: any) => {
    dispatch(setManualBonusList(response.items));
    dispatch(setManualBonusCount(response.count));
    return Promise.resolve(response);
  },
);

export const updateBonusBalance = (bonus: number, accountId: string | number): ThunkAction => (dispatch, getState, http) => http.post(
  `/api/v1/accounts/bonus/${accountId}/add/`, { bonus },
).then(
  () => {
    dispatch(loadCurrentAccountBalance(accountId));
  },
);

export const createManualBonus = (message: string, bonus_value: number, account_id: string | number): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/chat/comment/bonus/create/', { message, bonus_value, account_id },
);

export const requestChangeAccountLimit = (limit: number, accountId: string | number): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/limit/billing/${accountId}/request/`, { limit },
);

export const changeAccountStatus = (accountId: number, status: AccountStatusType): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/accounts/status/${accountId}/change/`,
  { status },
).then(
  () => dispatch(loadAccount(accountId)),
);

export const loadAccountNotificationPreferences = (accountId: string | number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/accounts/notification_preference/${accountId}/`,
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
    return Promise.resolve(preferences);
  },
);

export const addAccountEmailNotificationPreferencesItem = (preference: NotificationPreference, item: anyObject, accountId: string | number): ThunkAction => (
  dispatch, getState, http,
) => http.put(
  `/api/v1/accounts/notification_preference/${accountId}/add/`,
  {
    preference,
    item,
  },
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
  },
);

export const updateAccountEmailNotificationPreferencesItem = (preference: NotificationPreference, item: anyObject, accountId: string | number): ThunkAction => (
  dispatch, getState, http,
) => http.put(
  `/api/v1/accounts/notification_preference/${accountId}/update/`,
  {
    preference,
    item,
  },
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
  },
);

export const deleteAccountEmailNotificationPreferencesItem = (preference: NotificationPreference, itemId: number, accountId: string | number): ThunkAction => (
  dispatch, getState, http,
) => http.delete(
  `/api/v1/accounts/notification_preference/${accountId}/remove/`,
  {
    preference,
    item_id: itemId,
  },
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
  },
);

export const updateGlobalBillingLimit = (accountId: number | string, limit: any): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/billing/global_limit/${accountId}/update/`,
  { limit },
);

export const updateBitrixId = (accountId: number | string, bitrixId: number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/accounts/bitrix_id/${accountId}/update/`,
  adaptToApi({ bitrixId }),
).then(
  (response: { bitrixId: number }) => {
    dispatch(setCurrentAccountBitrixId(response.bitrixId));
    return Promise.resolve(response);
  },
);;

export const switchAccountAlternativeInvoice = (accountId: number | string, alternativeInvoice: boolean): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/accounts/tender/${accountId}/alternative_invoice/switch/`,
  { alternative_invoice: alternativeInvoice },
).then(
  () => {
    const { currentAccount } = getState().accounts;

    if (currentAccount?.accountId === accountId) {
      dispatch(setCurrentAccount({ ...currentAccount, tenderConfigurations: { ...currentAccount.tenderConfigurations, alternativeInvoice } }));
    }
  },
);

export const loadCompanySpends = (accountId: number, data: any): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/salary/company_spends/${accountId}/`,
  adaptToApi(data),
).then(
  (response: any) => {
    dispatch(setCompanySpends(adaptFromApi(response.items)))
  },
);